@import '../../shared/variables';

.view {
    .view-dashboard .sales-card {
        .card + .card .card-body {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid $md-blue-grey-50;
            svg {
                transform: translate(0, -2px);
            }
        }
        .card + .card .card-body:last-child {
            border: 0;
        }
    }
}

.pie-chart-container {
    zoom: 0.95
}

@media screen and (max-width: 480px) {
    .pie-chart-container {
        zoom: 0.75
    }
}

@media screen and (max-width: 410px) {
    .pie-chart-container {
        zoom: 0.65
    }
}

.locked-section {
    opacity: 0.3;
    pointer-events: none;
}

.pending-actions-alert {
    cursor: pointer;
    .badge-container {
        position: absolute;
        top: -5px;
        animation: badge-wobble 1s ease-out 1s infinite alternate;
    }
    @media screen and (orientation: landscape) {
        .badge-container {
            left: 0;
        }
    }
    @keyframes badge-wobble {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.3);
        }
    }
    .arrow-container {
        text-align: right;
    }
    .arrow {
        transition-duration: 500ms;
        &.open {
            transform: rotateX(180deg);
        }
    }
    .fix-text {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    @media screen and (orientation: portrait) {
        .arrow-container {
            margin-top: 0.7rem;
            text-align: center;
        }
        .badge-container {
            right: 0;
        }
        .fix-text {
            text-decoration: underline;
        }
    }
}


