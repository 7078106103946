.tablet-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 8.5rem;
    .tablet-icon-container {
        min-width: 90px; 
        text-align: center;
    }
    .screen-size-container {
        flex: 0.8;
    }
    .quantity-container {
        justify-content: flex-end;
        flex: 1;
        flex-direction: row;
        display: flex;
    }
}

.checkout-container {
    align-items: center
}

@media (orientation: portrait) { 
    .kiosk-price {
        display: none;
    }
}