@import '../../shared/variables';

.add-new-category {
    transition-duration: .5s;
    cursor: pointer;
    &:hover {
        transition-duration: .5s;
    }
}

.refund-container {
    text-align: right;
}

.donation-link {
    overflow: hidden;
    text-overflow: ellipsis;
} 

.widget-instructions {
    code {
        word-break: break-word;
    }
}

.eoy-email-template-container textarea {
    height: 230px;
}

@media screen and (orientation: portrait) {
    .widget-snippet {
        display: none;
    }
}