@import './shared/variables';

.operator-mode-container {
    position: fixed;
    right: 8%;
    bottom: 8%;
    border-radius: 10px;
    border: 1px solid $btn-secondary-border;
    box-shadow: 1px 1px 1px $btn-secondary-border;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    font-size: 1.5rem;
    text-align: center;
    div {
        font-size: 0.8rem;
        color: $brand-primary;
    }
}
.flyer-description{
    width: 230px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (orientation: portrait) {
    .operator-mode-container {
        font-size: 1.2rem;
        div {
            display: none;
        }
    }
}