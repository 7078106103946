.disabled-checkbox-group {
    @extend .disabled;
    cursor: not-allowed;
    .form-group {
        pointer-events: none;
    }
}

.disabled {
    opacity: 0.3;
}
