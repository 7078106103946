.fade-in-out {
    opacity: 1;
    -webkit-animation: fadeinout 1.5s linear infinite;
    animation: fadeinout 1.5s linear infinite;
}

@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
@keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
}