@media (orientation: landscape) { 
    .modal-xlg {
       max-width: 90% !important; 
    }
 }

 .salat-modal {
    margin-top: 1rem !important;
 }

 .salat-input-container {
     align-items: center;
     justify-content: center;
     border-bottom: 1px solid #eceeef;
     &:last-child {
      border-bottom: 0px;
     }
 }

 .copy-buttons {
    padding-top: 1rem;
 }

 @media (orientation: portrait) { 
   .copy-buttons {
      padding-top: 0 !important; 
   }
}

